import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import { only, down } from 'styled-breakpoints'
import BaseBackToTop from '../BaseBackToTop/BaseBackToTop'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${vars.COLOR_WHITE_1};
  font-family: ${vars.FONT_FAMILY_1};
  ${down('md')} {
    display: block;
    > *:not(:last-child) {
      margin-right: 0;
    }
  }
  ${only('sm')} {
    display: flex;
    > *:not(:last-child) {
      margin: 2rem 0;
    }
  }
`
const CopyTitle = styled.div`
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
`
const CopyBody = styled.div`
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  font-size: 1.1rem;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  justify-content: space-between;
  ${only('sm')} {
    flex-direction: row;
    order: 1;
  }
  ${only('sm')} {
    flex-direction: column;
    order: 1;
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
  margin-bottom: 2rem;
  ${only('sm')} {
    flex-direction: column;
  }
  &:not(:last-child) {
    ${only('sm')} {
      margin-right: 0;
    }
  }
`
const Links = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: underline;
  }
  > :first-child {
    margin-right: 3em;
    max-width: 400px;
  }
  ${only('sm')} {
    order: 3;
  }
`
const Linkedin = styled.img`
  width: 2.5rem;
`
const Logo = styled.img`
  width: 27rem;
  ${down('md')} {
    width: 19rem;
  }
  ${only('sm')} {
    display: none;
  }
`
const Logo2 = styled.img`
  display: none;
  ${down('sm')} {
    order: 1;
    display: block;
    width: 23rem;
  }
`
const OfficeInfo = styled.div`
  font-size: 1.2rem;
  flex: 1;
  line-height: 1.75;
  &:not(:last-child) {
    margin-right: 4rem;
  }
  &:not(:last-child) {
    ${only('sm')} {
      flex: 0;
      margin-right: 0;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
    }
  }
`
const Text1 = styled.div`
  font-size: 1.2rem;
  line-height: 1.75;
`
const Anchor = styled.a`
  display: inline-block;
  ${only('md')} {
    display: inline;
  }
  ${only('sm')} {
    order: 2;
  }
`
const Anchor2 = styled.a`
  display: flex;
  align-self: flex-start;
  ${only('md')} {
    display: inline;
  }
  ${only('sm')} {
    order: 2;
  }
`
const Copyright = styled.div`
  margin: 1rem 0 0 0;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6rem;
`

export default () => (
  <Container>
    <Header>
      <Anchor2 href="https://quantium.com/" target="_blank">
        <Logo src={require('../../assets/img/logo-white.svg')} />{' '}
      </Anchor2>{' '}
      <BaseBackToTop backToTop="#___gatsby" />
    </Header>{' '}
    <Content>
      <Row>
        <OfficeInfo>
          <CopyTitle> Sydney(HQ) </CopyTitle>{' '}
          <CopyBody>
            Bay 12, 2 Locomotive Street, <br />
            Eveleigh, NSW 2015, Australia <br />
            Phone:{' '}
            <Anchor href="tel:+61 2 9292 6400"> + 61 2 9292 6400 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Melbourne </CopyTitle>{' '}
          <CopyBody>
            Level 10, 271 Collins St, <br />
            Melbourne VIC, 3000 Australia <br />
            Phone:{' '}
            <Anchor href="tel:+61 3 8602 0100"> + 61 3 8602 0100 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Brisbane </CopyTitle>{' '}
          <CopyBody>
            Level 17, 144 Edward Street, <br />
            Brisbane QLD, 4000 Australia <br />
            Phone:{' '}
            <Anchor href="tel:+61 7 3020 2400"> + 61 7 3020 2400 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Canberra </CopyTitle>{' '}
          <CopyBody>
            Suite 2.01, Level 2, 17 Moore Street, <br />
            Canberra ACT, 2601 Australia <br />
            Phone:{' '}
            <Anchor href="tel:+61 2 9292 6400"> + 61 2 9292 6400 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
      </Row>{' '}
      <Row>
        <OfficeInfo>
          <CopyTitle> Auckland </CopyTitle>{' '}
          <CopyBody>
            Level 10 11 Britomart Place <br />
            Auckland 1010 <br />
            New Zealand <br />
            Phone:{' '}
            <Anchor href="tel:+64 212 715 667"> + 64 212 715 667 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> San Francisco </CopyTitle>{' '}
          <CopyBody>
            75 Broadway, Suite 202 - #1903
            <br />
            San Francisco, CA 94111, United States of America <br />
            Phone:{' '}
            <Anchor href="tel:+415 651 5199">
              {' '}
              + 415 651 5199 {'  '}{' '}
            </Anchor> or{' '}
            <Anchor href="tel:+1800 736 2244"> +1800 736 2244 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Bentonville </CopyTitle>{' '}
          <CopyBody>
            609 SW 8 th St Suite 310, Bentonville, Arkansas, 72712, United
            States of America <br />
            Phone: <Anchor href="tel:+415 651 5199">
              {' '}
              + 415 651 5199{' '}
            </Anchor> or{' '}
            <Anchor href="tel:+1800 736 2244"> +1800 736 2244 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> London </CopyTitle>{' '}
          <CopyBody>
            We Work, Aldwych House, <br />
            71 - 91 Aldwych, London WC2B 4 HN, <br />
            United Kingdom <br />{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
      </Row>{' '}
      <Row>
        <OfficeInfo>
          <CopyTitle> Johannesburg </CopyTitle>{' '}
          <CopyBody>
            Ground Floor, WeWork, The Link, 173 Oxford Road, <br />
            Rosebank, Johannesburg 2196, <br />
            South Africa <br />
            Phone:{' '}
            <Anchor href="tel:+27 10 592 1810"> + 27 10 592 1810 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Chicago </CopyTitle>{' '}
          <CopyBody>
            1 S Dearborn, 20 th Floor, <br />
            Chicago, IL 60603, <br />
            United States of America <br />
            Phone: <Anchor href="tel:+415 651 5199">
              {' '}
              + 415 651 5199{' '}
            </Anchor> or{' '}
            <Anchor href="tel:+1800 736 2244"> +1800 736 2244 </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> Hyderabad </CopyTitle>{' '}
          <CopyBody>
            Mezzanine Level, Part E of Tower 2.1, <br />
            Waverock Building, APIIC(IT / ITES SEZ), <br />
            Nanakramguda, Serilingampally, <br />
            Hyderabad 500032, India <br />
            Phone:{' '}
            <Anchor href="tel:+91 7331135111">
              {' '}
              +91 7331135111{' '}
            </Anchor>{' '}
          </CopyBody>{' '}
        </OfficeInfo>{' '}
        <OfficeInfo>
          <CopyTitle> New York </CopyTitle>{' '}
          <CopyBody>
          524 Broadway <br /> 
          New York <br /> 
          NY 10012 <br /> 
          United States
          </CopyBody>{' '}
        </OfficeInfo>{' '}
      </Row>{' '}
    </Content>{' '}
    <Logo2 src={require('../../assets/img/logo-white.svg')} />{' '}
    <Links>
      <Text1>
        <Anchor href="https://quantium.com/privacy/"> Privacy policy </Anchor> |{' '}
        <Anchor href="https://quantium.com/terms-of-use/">
          {' '}
          Terms of use{' '}
        </Anchor>{' '}
        |{' '}
        <Anchor href="https://quantium.com/whistleblowing-policy/">
          {' '}
          Whistleblowing policy{' '}
        </Anchor>{' '}
        |{' '}
        <Anchor href="https://quantium.com/modern-slavery-policy/">
          {' '}
          Modern slavery policy{' '}
        </Anchor>{' '}
        |{' '}
        <Anchor href="https://quantium.com/information-security-policy/">
          {' '}
          Information security policy{' '}
        </Anchor>{' '}
        <br />
        <Copyright> Copyright© 2024 Quantium </Copyright>{' '}
      </Text1>{' '}
      <Anchor href="https://www.linkedin.com/company/quantium/" target="_blank">
        <Linkedin src={require('../../assets/img/linkedin.svg')} />{' '}
      </Anchor>{' '}
    </Links>{' '}
  </Container>
)
