import React from 'react'
import styled from 'styled-components'
import BaseButton from '../../components/BaseButton/BaseButton'
import { only } from 'styled-breakpoints'
import { FormMode } from '../../config/form-mode'
import ScrollAnimation from 'react-animate-on-scroll'

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 10rem 0;
  ${only('sm')} {
    flex-direction: column;
    padding: 0;
  }
`
const CopyContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img`
  max-width: 39rem;
  width: 100%;
  margin-bottom: 2rem;
  ${only('sm')} {
    max-width: 24rem;
    margin-bottom: 3rem;
  }
`
const Paragraph = styled.div`
  // text-align: center;
  max-width: 35rem;
  width: 100%;
  line-height: 1.5;
`
const Spacer = styled.div`
  width: 4rem;
  flex-shrink: 0;
  ${only('sm')} {
    height: 6rem;
  }
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    width: 100%;
    &:first-child {
      margin-bottom: 2rem;
    }
  }
`
const Span = styled.span`
  text-decoration: underline;
  cursor: pointer;
  display: inline;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
  }
  componentDidMount() {
    this.io = new IntersectionObserver(([entry]) =>
      this.props.onIntersectionUpdate(entry.isIntersecting)
    )
    this.io.observe(this.containerRef.current)
  }
  componentWillUnmount() {
    this.io.disconnect()
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fade-in-up"
        animateOnce={true}
        style={{ willChange: 'transform, opacity' }}
      >
        <Container ref={this.containerRef}>
          <CopyContainer>
            <Logo src={require('../../assets/img/logo-refinery.png')} />
            <Paragraph>
              Ready to power your transaction data?
              <br />
              <Span
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.TALKTOUS,
                  })
                }
              >
                Talk to us.
              </Span>
            </Paragraph>
          </CopyContainer>
          <Spacer />
          <ButtonsContainer>
            <BaseButton
              text="Download brochure"
              textAlign="left"
              theme="black"
              displayArrow
              onClick={() =>
                this.props.updateFormState({
                  formIsOpen: true,
                  formMode: FormMode.BROCHURE,
                })
              }
            />
            <BaseButton
              text="Request demo"
              textAlign="left"
              theme="black"
              displayArrow
              onClick={() =>
                this.props.updateFormState({
                  formIsOpen: true,
                  formMode: FormMode.DEMO,
                })
              }
            />
          </ButtonsContainer>
        </Container>
      </ScrollAnimation>
    )
  }
}
