import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import { only, down } from 'styled-breakpoints'
import BaseVideo from '../BaseVideo/BaseVideo'
import ScrollAnimation from 'react-animate-on-scroll'
import BaseButton2 from '../BaseButton2/BaseButton2'
import scrollTo from 'gatsby-plugin-smoothscroll'
import anime from 'animejs'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  color: ${vars.COLOR_WHITE_1};
`
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // max-width: 40.5rem;
  max-width: 39rem;
  align-self: center;
  ${down('md')} {
    max-width: 100%;
  }
`
const Title = styled.div`
  font-size: 4.2rem;
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
  margin: 3rem 0;
  line-height: 1.15;
  ${down('md')} {
    font-size: 3.5rem;
    // margin-bottom: 6rem;
  }
  ${only('sm')} {
    font-size: 3rem;
    // margin-bottom: 4rem;
    margin-bottom: 0;
  }
`
const VideoContainer = styled.div`
  position: relative;
  width: ${vars.CONTENT_WIDTH};
  max-width: calc(100vw - 8rem);
  overflow: hidden;
  margin: 5rem 0;
  height: 0;
`
const VideoInner = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 4.2rem;
`
const IllustrationContainerSm = styled.div`
  display: none;
  ${only('sm')} {
    position: relative;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
    // margin-bottom: 4rem;
    cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  }
`
const IllustrationContainerMd = styled.div`
  display: none;
  ${only('md')} {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 6rem;
    cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  }
`
const Paragraph = styled.div`
  font-size: 2rem;
  font-family: ${vars.FONT_FAMILY_2};
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  line-height: 1.5;
  ${down('md')} {
    margin-bottom: 0;
    font-size: 2rem;
  }
  ${only('sm')} {
    font-size: 1.6rem;
  }
`
const Spacer = styled.div`
  width: 10%;
  ${down('md')} {
    display: none;
  }
`
const IllustrationContainerLg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  ${down('md')} {
    display: none;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  margin: 3.5rem 0 0 0;
  > * {
    margin: 0 2rem 0 0;
    ${only('sm')} {
      margin: 0 0 2rem 0;
    }
  }
  ${only('sm')} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoIsOpen: false,
      videoUrl: null,
    }
    this.videoContainerRef = React.createRef()
    this.videoInnerRef = React.createRef()
    this.baseVideoRef = React.createRef()
  }
  setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve))
  }
  async updateVideoState({ videoIsOpen, videoUrl }) {
    if (this.isAnimating) return
    this.isAnimating = true
    if (videoIsOpen) {
      await this.setStateAsync({ videoIsOpen, videoUrl })
      // await this.baseVideoRef.current.plyr.play()
      await this.animateVideo('in')
    } else {
      await this.animateVideo('out')
      this.baseVideoRef.current.plyr.stop()
      await this.setStateAsync({ videoIsOpen, videoUrl })
    }
    this.isAnimating = false
  }
  animateVideo(direction) {
    return anime(
      {
        targets: this.videoContainerRef.current,
        height:
          direction === 'in'
            ? ['0px', this.videoInnerRef.current.offsetHeight + 'px']
            : [this.videoContainerRef.current.offsetHeight + 'px', '0px'],
        complete:
          direction === 'in' &&
          (() => (this.videoContainerRef.current.style.height = 'auto')),
        easing: 'easeInOutQuint',
        duration: 600,
      },
      0
    ).finished
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fade-in-up"
        animateOnce={true}
        style={{ willChange: 'transform, opacity' }}
      >
        <Container>
          <Content>
            <Title>{this.props.title}</Title>

            {/* {!this.state.videoIsOpen && ( */}
            <IllustrationContainerMd>
              {this.props.illustrationMd}
            </IllustrationContainerMd>
            {/* )} */}

            <Paragraph>
              {this.props.paragraph}
              <ButtonContainer>
                {/* <BaseButton2
                  onClick={() => {
                    scrollTo('#videoAnchor4')
                    this.updateVideoState({videoIsOpen: true, videoUrl: 'bxvKoPzgsfQ'})
                  }}
                  text={this.props.buttonText1}
                  theme="white"
                  displayArrow
                /> */}
                <BaseButton2
                  onClick={() => {
                    scrollTo('#videoAnchor4')
                    this.updateVideoState({
                      videoIsOpen: true,
                      videoUrl: '7Iag741l7Vg',
                    })
                  }}
                  text={this.props.buttonText2}
                  theme="white"
                  displayArrow
                />
              </ButtonContainer>
            </Paragraph>

            <VideoContainer
              id={this.props.videoAnchor}
              ref={this.videoContainerRef}
            >
              <VideoInner ref={this.videoInnerRef}>
                <BaseVideo
                  ref={this.baseVideoRef}
                  src={this.state.videoUrl}
                  onClose={() => {
                    scrollTo('#section3')
                    this.updateVideoState({ videoIsOpen: false })
                  }}
                  theme="white"
                />
              </VideoInner>
            </VideoContainer>

            {!this.state.videoIsOpen && (
              <IllustrationContainerSm>
                {this.props.illustrationSm}
              </IllustrationContainerSm>
            )}
          </Content>
          <Spacer />
          {!this.state.videoUrl && (
            <IllustrationContainerLg>
              {this.props.illustrationLg}
            </IllustrationContainerLg>
          )}
        </Container>
      </ScrollAnimation>
    )
  }
}
