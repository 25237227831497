import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import BaseNavigation from '../../components/BaseNavigation/BaseNavigation'
import BaseButton from '../../components/BaseButton/BaseButton'
import { down, only } from 'styled-breakpoints'
import { FormMode } from '../../config/form-mode'
import BaseVideo from '../../components/BaseVideo/BaseVideo'
import BasePlayButton from '../../components/BasePlayButton/BasePlayButton'
import ScrollAnimation from 'react-animate-on-scroll'
import scrollTo from 'gatsby-plugin-smoothscroll'
import anime from 'animejs'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`
const HeaderWrapper = styled.div`
  display: flex;
`
const Content = styled.div`
  max-width: 46rem;
  ${down('md')} {
    max-width: 100%;
  }
`
const Title = styled.div`
  font-size: 4.2rem;
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
  margin-bottom: 4.2rem;
  line-height: 1.15;
  ${down('md')} {
    font-size: 3.5rem;
    margin-bottom: 6rem;
  }
  ${only('sm')} {
    font-size: 3rem;
    margin-bottom: 4rem;
  }
`
const VideoContainer = styled.div`
  position: relative;
  width: ${vars.CONTENT_WIDTH};
  max-width: calc(100vw - 8rem);
  overflow: hidden;
  height: 0;
`
const VideoInner = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 4.2rem;
`
const MobileIllustrationContainer = styled.div`
  display: none;
  ${down('md')} {
    display: flex;
    align-items: center;
    margin-bottom: 4.2rem;
    cursor: pointer;
  }
`
const MobilePlayButtonContainer = styled.div`
  position: relative;
  width: 4.1rem;
  height: 4.1rem;
  margin-right: 2rem;
`
const MobilePlayButton = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const MobileIllustration = styled.img`
  position: absolute;
  width: 50rem;
  height: 50rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`
const MobilePlayCopy = styled.div`
  width: 100%;
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
  font-size: 1.8rem;
`
const Paragraph = styled.div`
  font-size: 2rem;
  font-family: ${vars.FONT_FAMILY_2};
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  margin-bottom: 4.5rem;
  line-height: 1.5;
  ${down('md')} {
    font-size: 2rem;
  }
  ${only('sm')} {
    font-size: 1.6rem;
  }
`
const Buttons = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin: 0 2rem 0 0;
  }
  ${only('sm')} {
    flex-direction: column;
    > *:not(:last-child) {
      margin: 0 0 2rem 0;
    }
  }
`
const Spacer = styled.div`
  width: 5%;
`
const DesktopIllustrationContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${down('md')} {
    display: none;
  }
`
const DesktopPlayButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
`
const DesktopIllustration = styled.img`
  width: 100%;
`

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { videoIsOpen: false }
    this.containerRef = React.createRef()
    this.basePlayButtonRef = React.createRef()
    this.videoContainerRef = React.createRef()
    this.videoInnerRef = React.createRef()
    this.baseVideoRef = React.createRef()
  }
  componentDidMount() {
    this.io = new IntersectionObserver(([entry]) =>
      this.props.onIntersectionUpdate(entry.isIntersecting)
    )
    this.io.observe(this.containerRef.current)
  }
  componentWillUnmount() {
    this.io.disconnect()
  }
  setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve))
  }
  async updateVideoState({ videoIsOpen }) {
    if (this.isAnimating) return
    this.isAnimating = true
    if (videoIsOpen) {
      await this.setStateAsync({ videoIsOpen })
      // await this.baseVideoRef.current.plyr.play()
      await this.animateVideo('in')
    } else {
      await this.animateVideo('out')
      this.baseVideoRef.current.plyr.stop()
      await this.setStateAsync({ videoIsOpen })
    }
    this.isAnimating = false
  }
  animateVideo(direction) {
    return anime(
      {
        targets: this.videoContainerRef.current,
        height:
          direction === 'in'
            ? ['0px', this.videoInnerRef.current.offsetHeight + 'px']
            : [this.videoContainerRef.current.offsetHeight + 'px', '0px'],
        complete:
          direction === 'in' &&
          (() => (this.videoContainerRef.current.style.height = 'auto')),
        easing: 'easeInOutQuint',
        duration: 600,
      },
      0
    ).finished
  }
  render() {
    return (
      <ScrollAnimation
        animateIn="fade-in-up"
        animateOnce={true}
        style={{ willChange: 'transform, opacity' }}
      >
        <Container ref={this.containerRef} id="section-header">
          <BaseNavigation
            anchor1={this.props.anchor1}
            anchor2={this.props.anchor2}
            anchor3={this.props.anchor3}
            anchor4={this.props.anchor4}
            anchor5={this.props.anchor5}
          />
          <HeaderWrapper>
            <Content>
              <Title>
                Unlock the power of transaction data to transform your Bank's
                experience
              </Title>
              <VideoContainer ref={this.videoContainerRef} id="videoAnchor1">
                <VideoInner ref={this.videoInnerRef}>
                  <BaseVideo
                    ref={this.baseVideoRef}
                    src="Jsth4T2S9Ks"
                    onClose={() => {
                      this.updateVideoState({ videoIsOpen: false })
                      scrollTo('#section-header')
                    }}
                    theme="black"
                  />
                </VideoInner>
              </VideoContainer>
              {!this.state.videoIsOpen && (
                <MobileIllustrationContainer
                  onClick={() => this.updateVideoState({ videoIsOpen: true })}
                >
                  <MobilePlayButtonContainer>
                    <MobilePlayButton
                      src={require('../../assets/img/play.svg')}
                    />
                    <MobileIllustration
                      src={require('./assets/rainbow-only-mobile.png')}
                    />
                  </MobilePlayButtonContainer>
                  <MobilePlayCopy>How it works</MobilePlayCopy>
                </MobileIllustrationContainer>
              )}
              <Paragraph>
                Your transaction data is an asset of incredible value.
                <br />
                <br />
                Q.Refinery is a global, industry-leading solution that turns
                your unstructured transaction data into the foundations for
                transformed customer and business outcomes.
                <br />
                <br />
                Through our work with many of the largest global banks, we have
                unlocked hundreds of millions of dollars in value through
                enhanced solutions across the retail and commercial bank.
              </Paragraph>
              <Buttons>
                <BaseButton
                  theme="black"
                  text="Download brochure"
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.BROCHURE,
                    })
                  }
                />
                <BaseButton
                  theme="black"
                  text="Request a demo"
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.DEMO,
                    })
                  }
                />
              </Buttons>
            </Content>
            <Spacer />
            {!this.state.videoIsOpen && (
              <DesktopIllustrationContainer
                onClick={() => {
                  this.updateVideoState({ videoIsOpen: true })
                  scrollTo('#videoAnchor1')
                }}
                onMouseEnter={() =>
                  this.basePlayButtonRef.current.onMouseEnter()
                }
                onMouseLeave={() =>
                  this.basePlayButtonRef.current.onMouseLeave()
                }
              >
                <DesktopPlayButtonContainer>
                  <BasePlayButton ref={this.basePlayButtonRef} theme="white" />
                </DesktopPlayButtonContainer>
                <DesktopIllustration
                  src={require('./assets/rainbow-only-desktop.png')}
                />
              </DesktopIllustrationContainer>
            )}
          </HeaderWrapper>
        </Container>
      </ScrollAnimation>
    )
  }
}
