import React from 'react'
import styled from 'styled-components'
import { gsap, Power2, Linear } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import throttle from 'lodash.throttle'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`
const SVG = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: visible;
  z-index: 0;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    gsap.registerPlugin(DrawSVGPlugin)
    gsap.registerPlugin(MotionPathPlugin)
    this.svg = React.createRef()
    this.blackPath = React.createRef()
    this.circle = React.createRef()
  }
  componentDidMount() {
    this.tl = this.createTl()
    this.io = this.createIo()
    this.io.observe(this.svg.current)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  createTl() {
    const tl = new gsap.timeline({ paused: true })
    tl.fromTo(
      this.blackPath.current,
      { drawSVG: `0% 100%` },
      { drawSVG: `100% 100%`, ease: Linear.easeNone, duration: 1 },
      0
    )
    tl.to(
      this.circle.current,
      {
        motionPath: {
          path: this.blackPath.current,
          align: this.blackPath.current,
          alignOrigin: [0.5, 0.5],
          start: 0,
          end: 1,
        },
        ease: Linear.easeNone,
        duration: 1,
      },
      0
    )
    return tl
  }
  createIo() {
    const io = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting)
          return window.addEventListener('scroll', this.handleScroll)
        else {
          if (entry.boundingClientRect.top > 0) this.updateLineAnimation(0)
          else this.updateLineAnimation(1)
          window.removeEventListener('scroll', this.handleScroll)
        }
      },
      { rootMargin: '-50% 0% -50% 0%' }
    )
    return io
  }
  updateLineAnimation = percentComplete => {
    if (typeof percentComplete !== 'number') {
      const { top, height } = this.svg.current.getBoundingClientRect()
      percentComplete = (window.innerHeight / 2 + -1 * top) / height
      if (percentComplete < 0 || percentComplete > 1) return
    }
    gsap.to(this.tl, {
      progress: percentComplete,
      duration: 1,
      ease: Power2.easeOut,
    })
  }
  handleScroll = throttle(() => this.updateLineAnimation(), 100)
  render() {
    return (
      <Container className="line-container">
        <SVG
          ref={this.svg}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1753 2776"
          preserveAspectRatio="xMidYMid slice"
        >
          <path
            strokeWidth="5"
            strokeDasharray="0 23"
            fill="none"
            stroke="#4a4a4e"
            strokeLinecap="round"
            d="M874.679,11C467.293,11,2.5,138.151,2.5,449.4c0,640.011,1748,342.194,1748,978.517,0,643.755-1748,218.585-1748,886.026C2.5,2719.968,649.08,2766,874.679,2766"
          />
          <path
            ref={this.blackPath}
            strokeWidth="20"
            stroke="#000"
            fill="none"
            d="M874.679,11C467.293,11,2.5,138.151,2.5,449.4c0,640.011,1748,342.194,1748,978.517,0,643.755-1748,218.585-1748,886.026C2.5,2719.968,649.08,2766,874.679,2766"
          />
          <circle
            ref={this.circle}
            cx="874.679"
            cy="11"
            r="10"
            fill="#4a4a4e"
          />
          <circle cx="874.679" cy="11" r="10" fill="#4a4a4e" />
        </SVG>
      </Container>
    )
  }
}
