import React from 'react'
import styled from 'styled-components'
import { only } from 'styled-breakpoints'
import BaseSection9 from '../../components/BaseSection9/BaseSection9'
import BasePlayButton from '../../components/BasePlayButton/BasePlayButton'
import BaseButton from '../../components/BaseButton/BaseButton'
import BaseSwiper2 from '../../components/BaseSwiper2/BaseSwiper2'

const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
`
const IllustrationSm = styled.img`
  width: 90%;
`
const IllustrationMd = styled.img`
  width: 70%;
`
const IllustrationLgContainer = styled.div`
  position: relative;
  width: 100%;
`
const PlayButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22%;
`
const IllustrationLg = styled.img`
  width: 100%;
  margin-left: 8px;
`
const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  margin: 3.5rem 0 0 0;
  ${only('sm')} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`
const Slide = styled.img`
  width: auto;
  height: 100px;
`
const Span = styled.span``

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.basePlayButtonRef = React.createRef()
  }
  render() {
    return (
      <BaseSection9
        title={
          <Span>
            Unrivalled speed and accuracy with unparalleled customer insight
          </Span>
        }
        videoUrl="K5ROUPbUyIo"
        paragraph={
          <Span>
            Many providers claim to cleanse and categorise banking transaction
            data, but not all solutions are created equal.
            <br />
            <br />
            Q.Refinery is the best in class capability, delivering fully
            productionised, industry leading coverage and accuracy. Together
            with an unparalleled breadth of customer attributes, Q.Refinery
            helps you to make business decisions that leverage a deeper
            understanding of each unique customer.
            <ButtonContainer>
              <a
                href="https://qrefinery.demo.api.quantium.com/docs/index.html"
                rel="noreferrer"
                target="_blank"
              >
                <BaseButton text="Developer portal" theme="white" />
              </a>
            </ButtonContainer>
          </Span>
        }
        illustrationSm2={
          <SwiperContainer>
            <BaseSwiper2
              slides={[
                <Slide src={require('./assets/slide-1.png')} />,
                <Slide src={require('./assets/slide-2.png')} />,
                <Slide src={require('./assets/slide-3.png')} />,
                <Slide src={require('./assets/slide-4.png')} />,
              ]}
            />
          </SwiperContainer>
        }
        illustrationSm1={
          <IllustrationSm src={require('./assets/illustration-sm1.png')} />
        }
        illustrationMd={
          <IllustrationMd src={require('./assets/illustration-lg.png')} />
        }
        illustrationLg={
          <IllustrationLgContainer
            onMouseEnter={() => this.basePlayButtonRef.current.onMouseEnter()}
            onMouseLeave={() => this.basePlayButtonRef.current.onMouseLeave()}
          >
            <PlayButtonContainer>
              <BasePlayButton ref={this.basePlayButtonRef} theme="black" />
            </PlayButtonContainer>
            <IllustrationLg src={require('./assets/illustration-lg.png')} />
          </IllustrationLgContainer>
        }
      />
    )
  }
}
