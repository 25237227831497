import React from 'react'
import styled from 'styled-components'
import { only } from 'styled-breakpoints'
import BaseSection8 from '../../components/BaseSection8/BaseSection8'
import BaseButton2 from '../../components/BaseButton2/BaseButton2'
import BaseSwiper2 from '../../components/BaseSwiper2/BaseSwiper2'
import Interaction from './assets/Interaction'
import scrollTo from 'gatsby-plugin-smoothscroll'

const IllustrationSm = styled.img`
  width: 90%;
`
const IllustrationMd = styled.img`
  width: 80%;
  margin-top: 5rem;
`
const Span = styled.span``
const Slide = styled.img`
  width: auto;
  height: 350px;
`
const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
`
const SwiperHeading = styled.div`
  display: none;
  font-weight: 500;
  text-align: center;
  margin: 0 0 2rem;
  font-size: 2.6rem;
  ${only('sm')} {
    display: block;
  }
`
const Paragraph = styled.div`
  pointer-events: none;
`
const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  margin: 3.5rem 0 0 0;
  ${only('sm')} {
    display: none;
    // display: flex;
    // flex-direction: column;
    // width: 100%;
  }
`

export default () => (
  <BaseSection8
    title={<Span>Put the customer at the heart of your business</Span>}
    videoUrl="3n6tenhZK7A"
    videoAnchor="videoAnchor2"
    paragraph={
      <div>
        <Paragraph>
          Customers expect a seamless, frictionless experience, uniquely
          tailored just for them. Are they getting it from you?
          <br />
          <br />
          Q.Refinery powers use cases that extend from understanding and
          predicting their needs, to providing personalised advice, products,
          and services. More complete, higher quality customer data is a
          foundational asset for better decisioning and enhanced
          personalisation.
          <br />
          <br />
          Watch how Q.Refinery unlocks the power in transaction data to deliver
          better customer and business outcomes.
        </Paragraph>
        <ButtonContainer onClick={() => scrollTo('#videoAnchor2')}>
          <BaseButton2 text="Watch video" theme="white" displayArrow />
        </ButtonContainer>
      </div>
    }
    illustrationSm2={
      <SwiperContainer>
        <SwiperHeading>I expect my bank to...</SwiperHeading>
        <BaseSwiper2
          slides={[
            <Slide src={require('./assets/slide-1.png')} />,
            <Slide src={require('./assets/slide-2.png')} />,
            <Slide src={require('./assets/slide-3.png')} />,
            <Slide src={require('./assets/slide-4.png')} />,
          ]}
        />
      </SwiperContainer>
    }
    illustrationSm1={
      <IllustrationSm src={require('./assets/illustration-sm1.png')} />
    }
    illustrationMd={
      <IllustrationMd src={require('./assets/illustration-lg.png')} />
    }
    illustrationLg={<Interaction />}
  />
)
