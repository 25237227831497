import React from 'react'
import styled from 'styled-components'
import { only, up } from 'styled-breakpoints'
import BaseCard from '../../components/BaseCard/BaseCard'
import { FormMode } from '../../config/form-mode'
import ScrollAnimation from 'react-animate-on-scroll'
import BaseSwiper4 from '../../components/BaseSwiper4/BaseSwiper4'
import vars from '../../assets/css/vars/vars'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Logo = styled.img`
  width: 60%;
  max-width: 45rem;
  margin: 2rem 0;
  flex-shrink: 0;
`
const Paragraph = styled.div`
  font-family: ${vars.FONT_FAMILY_2};
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  margin-bottom: 6rem;
  text-align: center;
  line-height: 1.5;
  max-width: 60rem;
`
const Cards = styled.div`
  position: relative;
  display: flex;
  width: 95%;
  flex-flow: row wrap;
  overflow: auto;
  > *:not(:nth-child(3n)) {
    margin-right: 6.5rem;
    ${only('md')} {
      margin-right: 2rem;
    }
  }
  > :nth-child(3n) {
    ${only('md')} {
      margin-right: 2rem;
    }
  }
  ${only('sm')} {
    flex-wrap: nowrap;
    width: calc(100% + 4rem * 2);
  }
`
const CardContainer = styled.div`
  width: 100%;
  display: flex;
  ${up('md')} {
    flex: 1;
  }
  ${only('sm')} {
    padding: 0 2rem;
    flex-shrink: 0;
    width: calc(100% - 2rem);
    display: none;
    &:not(:last-child) {
      margin-right: -2rem;
    }
  }
`
const SwiperContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  ${only('sm')} {
    display: block;
  }
`
const Slide = styled.img`
  width: 100%;
  height: auto;
  pointer-events: all;
  user-select: all;
`

export default class extends React.Component {
  render() {
    return (
      <ScrollAnimation
        animateIn="fade-in-up"
        animateOnce={true}
        style={{ willChange: 'transform, opacity' }}
      >
        <Container>
          <Logo src={require('../../assets/img/logo-roi.png')} />
          <Paragraph>
            Reach out to us for examples of how Q.Refinery has delivered
            significant value for global banking clients across multiple use
            cases, including those shown below.
          </Paragraph>

          <Cards>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE1,
                  })
                }
                image={require('../../assets/img/case-1.jpg')}
                title="Improve the efficiency of your marketing campaigns and supercharge your personalisation program"
                cta="Get the results"
                roi="2x ROI"
                as="div"
              />
            </CardContainer>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE2,
                  })
                }
                image={require('../../assets/img/case-2.jpg')}
                title="Reduce defaults, anticipate hardship and make better risk based pricing decisions"
                cta="Get the results"
                roi="1.5x ROI"
                as="div"
              />
            </CardContainer>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE3,
                  })
                }
                image={require('../../assets/img/case-3.jpg')}
                title="Step change your relationship with your business customers and unlock a new revenue stream"
                cta="Get the results"
                roi="2.5x ROI"
                as="div"
              />
            </CardContainer>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE4,
                  })
                }
                image={require('../../assets/img/case-4.jpg')}
                title="Drive new profits from additional home loans at less risk"
                cta="Get the results"
                roi="+10% annual value"
                as="div"
              />
            </CardContainer>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE5,
                  })
                }
                image={require('../../assets/img/case-5.jpg')}
                title="Increase your personal loan campaigns take up whilst reducing risk"
                cta="Get the results"
                roi="5x ROI"
                as="div"
              />
            </CardContainer>
            <CardContainer>
              <BaseCard
                onClick={() =>
                  this.props.updateFormState({
                    formIsOpen: true,
                    formMode: FormMode.CASE6,
                  })
                }
                image={require('../../assets/img/case-6.jpg')}
                title="Drive credit cards penetration and better predict defaults"
                cta="Get the results"
                roi="+25% applications"
                as="div"
              />
            </CardContainer>
          </Cards>

          <SwiperContainer>
            <BaseSwiper4
              slides={[
                <Slide
                  src={require('./assets/case-slide-1.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE1,
                    })
                  }
                />,
                <Slide
                  src={require('./assets/case-slide-2.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE2,
                    })
                  }
                />,
                <Slide
                  src={require('./assets/case-slide-3.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE3,
                    })
                  }
                />,
                <Slide
                  src={require('./assets/case-slide-4.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE4,
                    })
                  }
                />,
                <Slide
                  src={require('./assets/case-slide-5.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE5,
                    })
                  }
                />,
                <Slide
                  src={require('./assets/case-slide-6.jpg')}
                  onClick={() =>
                    this.props.updateFormState({
                      formIsOpen: true,
                      formMode: FormMode.CASE6,
                    })
                  }
                />,
              ]}
            />
          </SwiperContainer>
        </Container>
      </ScrollAnimation>
    )
  }
}
