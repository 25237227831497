import React from 'react'
import { only } from 'styled-breakpoints'
import vars from '../../assets/css/vars/vars'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 10rem 0;
  align-items: center;
  ${only('sm')} {
    flex-direction: column;
    align-items: center;
  }
`
const CopyContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Logo = styled.img`
  max-width: 39rem;
  width: 100%;
  margin-bottom: 2rem;
  ${only('sm')} {
    max-width: 24rem;
    margin-bottom: 3rem;
  }
`
const Paragraph = styled.div`
  // text-align: center;
  max-width: 35rem;
  width: 100%;
  line-height: 1.5;
`
const Spacer = styled.div`
  width: 4rem;
  flex-shrink: 0;
  ${only('sm')} {
    height: 6rem;
  }
`
const FormContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: ${props => (props.formMode === 'brochure' ? '785px' : '806px')};
  max-width: 47rem;
  background: ${vars.COLOR_WHITE_1};
  padding: 5rem 3rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
`
const Close = styled.img`
  width: 2.5rem;
  top: 0;
  right: 0;
`
const CloseCopy = styled.div`
  font-weight: 500;
  margin-right: 1.5rem;
`
const CloseContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  position: absolute;
  margin: 2rem 0;
  bottom: 100%;
  right: 0;
  cursor: pointer;
  pointer-events: all;
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  * {
    border-radius: 0 !important;
    box-shadow: none !important;
    font-family: ${vars.FONT_FAMILY_1} !important;
    box-sizing: border-box !important;
  }
  .hs-error-msgs {
    padding-left: 0 !important;
  }
  .form-columns-2 {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }
  .hs-form-field {
    width: auto !important;
    height: auto !important;
  }
  .input {
    margin: 0 !important;
  }
  input {
    width: 100% !important;
    height: auto !important;
    padding: 1.5rem 3rem !important;
    font-size: 1.8rem !important;
    font-family: ${vars.FONT_FAMILY_2} !important;
    color: ${vars.COLOR_BLACK_1} !important;
    background-color: ${vars.COLOR_WHITE_1} !important;
    border: solid 1px ${vars.COLOR_BLACK_1} !important;
    white-space: nowrap !important;
    ::placeholder {
      color: ${vars.COLOR_GRAY_2} !important;
    }
  }
  label {
    font-weight: ${vars.FONT_WEIGHT_REGULAR} !important;
  }
  .hs-form-field label:not(.hs-error-msg) {
    color: blue;
  }
  .actions {
    margin: 0 !important;
    padding: 0 !important;
  }
  .hs-submit {
    margin-top: 1.8rem !important;
  }
  .hs-button {
    width: 100%;
    font-weight: ${vars.FONT_WEIGHT_REGULAR} !important;
    padding: 1.5rem 3rem !important;
    font-family: ${vars.FONT_FAMILY_2} !important;
    font-size: 1.8rem !important;
    color: #fff !important;
    background-color: #000 !important;
    white-space: nowrap !important;
    transition: opacity ${vars.TRANSITION_SETTINGS} !important;
    text-align: left !important;
    cursor: pointer !important;
    text-shadow: none !important;
    line-height: 1.5 !important;
  }
  .hs-richtext {
    line-height: 1.5 !important;
  }
  .hs-recaptcha {
    margin-bottom: 3.2rem !important;
  }
  form {
    margin-bottom: 0 !important;
  }
  strong {
    font-weight: ${vars.FONT_WEIGHT_REGULAR} !important;
    text-decoration: underline !important;
  }
`

const hubSpotFormIds = {
  BROCHURE: 'a971c834-b4b7-4781-9589-3d6781fa0ed8',
  DEMO: '42307e3f-2ae2-4023-b8f5-851d3dae0c47',
  CASE1: 'a456fdce-83b7-429d-9fcb-7d1291400405',
  CASE2: '037d3c80-41c7-4023-a88f-4ff9ef7cd71e',
  CASE3: '81b77256-f3d8-4f8f-ba73-e50d8bc90e68',
  CASE4: '85a5a2f6-2960-41fa-a98e-b53878462bb1',
  CASE5: '1ea4ebf3-afe2-4b3f-a835-bd6d306765fd',
  CASE6: '944c6f76-584d-4f5c-add2-b634a72ea3e4',
  TALKTOUS: 'f5fe5e96-b179-450f-990b-38a2d9eb90aa',
}

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
  }
  componentDidMount() {
    this.initForm()
  }
  initForm() {
    const script = document.createElement('script')
    script.appendChild(
      document.createTextNode(`
      hbspt.forms.create({
        portalId: '2924288',
        formId: '${hubSpotFormIds[this.props.formMode]}'
      })
    `)
    )
    script.async = true
    this.formRef.current.appendChild(script)
  }
  render() {
    return (
      <Container>
        <CopyContainer>
          <Logo src={require('../../assets/img/logo-refinery.png')} />
          <Paragraph>
            Ready to power your transaction data?
            <br />
            <span>Talk to us.</span>
          </Paragraph>
        </CopyContainer>
        <Spacer />
        <FormContainer formMode={this.props.formMode}>
          <Form ref={this.formRef} />
          <CloseContainer onClick={this.props.close}>
            <CloseCopy>Close</CloseCopy>
            <Close src={require('../../assets/img/close-black.svg')} />
          </CloseContainer>
        </FormContainer>
      </Container>
    )
  }
}
