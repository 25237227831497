import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import { only } from 'styled-breakpoints'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${props => props.backgroundColor || vars.COLOR_WHITE_1};
  padding: ${props => props.padding || '6rem 4rem'};
  align-items: ${props => props.alignItems || 'center'};
  ${only('sm')} {
    align-items: flex-start;
  }
  ${props =>
    props.fixed &&
    `
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    overflow: auto;
  `}
`
const Content = styled.div`
  max-width: ${vars.CONTENT_WIDTH};
  width: 100%;
  z-index: 1;
`

export default React.forwardRef(
  ({ slot, padding, backgroundColor, fixed, alignItems }, ref) => (
    <Container
      ref={ref}
      padding={padding}
      backgroundColor={backgroundColor}
      fixed={fixed}
      alignItems={alignItems}
    >
      <Content>{slot}</Content>
    </Container>
  )
)
