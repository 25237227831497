import React from 'react'
import styled from 'styled-components'
import { only } from 'styled-breakpoints'
import '../../assets/img/roi-arrow.png'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  font-size: 1.6rem;
  padding: 1.5rem 3rem;
  align-self: center;
  font-weight: 500;
  cursor: pointer;
  ${only('sm')} {
    width: 100%;
    padding: 0;
    margin: 0;
    order: 3;
    border: none;
    font-size: 2rem;
  }
`
const Arrow = styled.img`
  position: relative;
  width: 2rem;
  margin-left: 3rem;
  margin-bottom: 0.5rem;
  pointer-events: none;
  ${only('sm')} {
    width: 2.5rem;
    margin-left: 2rem;
  }
`

export default class extends React.Component {
  render() {
    return (
      <Button onClick={() => scrollTo(`${this.props.backToTop}`)}>
        <div>Back to top</div>
        <Arrow src={require('../../assets/img/roi-arrow-up.png')} />
      </Button>
    )
  }
}
