import React from 'react'
import styled from 'styled-components'
import Swiper from 'swiper/js/swiper.esm.bundle'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`
const SwiperWrapper = styled.div`
  will-change: transform;
`
const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 8rem;
  margin: 4rem auto;
  z-index: 1;
`
const NavigationButton = styled.img`
  position: absolute;
  height: 2rem;
  width: auto;
  pointer-events: all;
  cursor: pointer;
  outline: none;
  z-index: 1;
`
const PrevButton = styled(NavigationButton)`
  left: 0%;
`
const NextButton = styled(NavigationButton)`
  right: 0%;
`
const Slide = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.swiperContainerRef = React.createRef()
    this.prevButtonRef = React.createRef()
    this.nextButtonRef = React.createRef()
    this.swiper = null
  }

  componentDidMount() {
    this.swiper = new Swiper(this.swiperContainerRef.current, {
      navigation: {
        nextEl: this.nextButtonRef.current,
        prevEl: this.prevButtonRef.current,
      },
      centeredSlides: true,
      slidesPerView: 1,
      loop: true,
      followFinger: true,
    })
  }

  render() {
    return (
      <Container>
        <div className="swiper-container" ref={this.swiperContainerRef}>
          <SwiperWrapper className="swiper-wrapper">
            {this.props.slides.map((slide, i) => (
              <Slide className="swiper-slide" key={i}>
                {slide}
              </Slide>
            ))}
          </SwiperWrapper>
        </div>
        <NavigationContainer>
          <PrevButton
            ref={this.prevButtonRef}
            src={require('../../assets/img/arrow-left-black-swiper.svg')}
          />
          <NextButton
            ref={this.nextButtonRef}
            src={require('../../assets/img/arrow-right-black-swiper.svg')}
          />
        </NavigationContainer>
      </Container>
    )
  }
}
