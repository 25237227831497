import React from 'react'
import styled from 'styled-components'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

const themes = {
  white: {
    closeImage: require('../../assets/img/close-white.svg'),
  },
  black: {
    closeImage: require('../../assets/img/close-black.svg'),
  },
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`
const CloseContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin: 5rem 0 3rem;
  cursor: pointer;
`
const Copy = styled.div`
  font-weight: 500;
  margin-right: 1.5rem;
`
const CloseButton = styled.img`
  align-self: center;
  width: 2.5rem;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  pointer-events: all;
  :hover {
    opacity: 1;
  }
`
const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.videoPlayerEl = React.createRef()
  }
  componentDidMount() {
    this.initPlyr()
    this.setSrc()
  }
  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) this.setSrc()
  }
  initPlyr() {
    this.plyr = new Plyr(this.videoPlayerEl.current, {
      autoplay: false,
    })
  }
  setSrc() {
    this.plyr.source = {
      type: 'video',
      title: this.props.title,
      dataPoster: this.props.poster,
      sources: [
        {
          src: this.props.src,
          type: 'video/mp4',
          provider: 'youtube',
          size: 720,
        },
      ],
    }
  }
  render() {
    return (
      <Container>
        <CloseContainer onClick={this.props.onClose}>
          <Copy>Close video</Copy>
          <CloseButton src={themes[this.props.theme].closeImage} />
        </CloseContainer>
        <VideoPlayer ref={this.videoPlayerEl} />
      </Container>
    )
  }
}
