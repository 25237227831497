import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

const Container = styled.div`
  width: 100%;
  position: relative;
  background-image: linear-gradient(to bottom, black 50%, white 50%);
  margin-top: -7vw;
  margin-bottom: -12.5vw;
  ${down('md')} {
    margin-top: -9vw;
    margin-bottom: -20vw;
  }
  ${down('sm')} {
    margin-top: -12vw;
    margin-bottom: -25vw;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    top: 36%;
    background-image: linear-gradient(
      to bottom,
      hsl(0, 0%, 0%) 0%,
      hsl(0, 0%, 11.29%) 8.1%,
      hsl(0, 0%, 22.04%) 15.5%,
      hsl(0, 0%, 32.25%) 22.5%,
      hsl(0, 0%, 41.88%) 29%,
      hsl(0, 0%, 50.92%) 35.3%,
      hsl(0, 0%, 59.33%) 41.2%,
      hsl(0, 0%, 67.09%) 47.1%,
      hsl(0, 0%, 74.16%) 52.9%,
      hsl(0, 0%, 80.5%) 58.8%,
      hsl(0, 0%, 86.07%) 64.7%,
      hsl(0, 0%, 90.81%) 71%,
      hsl(0, 0%, 94.66%) 77.5%,
      hsl(0, 0%, 97.54%) 84.5%,
      hsl(0, 0%, 99.36%) 91.9%,
      hsl(0, 0%, 100%) 100%
    );
  }
`
const Pattern = styled.img`
  position: relative;
  width: 100%;
  z-index: 1;
`

export default () => (
  <Container>
    <Pattern src={require('../../assets/img/wave-grid.svg')} />
  </Container>
)
