import React from 'react'
import styled from 'styled-components'
import Swiper from 'swiper/js/swiper.esm.bundle'
import vars from '../../assets/css/vars/vars'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
`
const SwiperWrapper = styled.div`
  will-change: transform;
`
const NavigationContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  max-width: 996px;
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
`
const NavigationButton = styled.img`
  position: absolute;
  height: 2rem;
  top: 40%;
  margin: 0 ${vars.SPACING_6};
  pointer-events: all;
  cursor: pointer;
  outline: none;
  z-index: 1;
`
const PrevButton = styled(NavigationButton)`
  left: 0%;
`
const NextButton = styled(NavigationButton)`
  right: 0%;
`
const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 1;
`
const Slide = styled.div`
  display: flex;
  justify-content: center;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.swiperContainerRef = React.createRef()
    this.prevButtonRef = React.createRef()
    this.nextButtonRef = React.createRef()
    this.swiper = null
  }

  componentDidMount() {
    this.swiper = new Swiper(this.swiperContainerRef.current, {
      navigation: {
        nextEl: this.nextButtonRef.current,
        prevEl: this.prevButtonRef.current,
      },
      centeredSlides: true,
      slidesPerView: 1,
      loop: true,
      followFinger: true,
    })
  }

  render() {
    return (
      <Container>
        <div className="swiper-container" ref={this.swiperContainerRef}>
          <SwiperWrapper className="swiper-wrapper">
            {this.props.slides.map((slide, i) => (
              <Slide className="swiper-slide" key={i}>
                {slide}
              </Slide>
            ))}
          </SwiperWrapper>
          <Gradient />
          <NavigationContainer>
            <PrevButton
              ref={this.prevButtonRef}
              src={require('../../assets/img/arrow-left-white-swiper.svg')}
            />
            <NextButton
              ref={this.nextButtonRef}
              src={require('../../assets/img/arrow-right-white-swiper.svg')}
            />
          </NavigationContainer>
        </div>
      </Container>
    )
  }
}
