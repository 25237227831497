import React from 'react'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'
import BaseButton from '../BaseButton/BaseButton'
import vars from '../../assets/css/vars/vars'

const Container = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${vars.COLOR_GRAY_1};
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
  margin-bottom: 4rem;
  cursor: pointer;
`
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(100% / 3 * 2);
`
const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 0;
  padding-top: calc(100% / 3 * 2);
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  // padding: 4.5rem 4rem;
  padding: 2.5rem;
  height: 100%;
  ${down('md')} {
    padding: 2rem;
  }
  ${down('sm')} {
    padding: 3rem 2rem;
  }
`
const CopyContainer = styled.div`
  margin-bottom: 2rem;
  flex: 1;
  ${down('md')} {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  ${down('sm')} {
    font-size: 2.3rem;
    margin-bottom: 3rem;
  }
`
const Title = styled.div`
  font-size: 2.2rem;
  line-height: 1.3;
  ${down('md')} {
    font-size: 1.8rem;
  }
  ${down('sm')} {
    font-size: 2.2rem;
  }
`
const RoiContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 1.5rem 0;
`
const Arrow = styled.img`
  position: relative;
  width: 0.7rem;
  height: auto;
  margin-right: 1rem;
  pointer-events: none;
`
const Roi = styled.div`
  font-size: 2.3rem;
  margin-top: 0.4rem;
`
const Paragraph = styled.div`
  font-family: ${vars.FONT_FAMILY_1};
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  font-size: 1.6rem;
  line-height: 1.4;
  margin-top: 2rem;
  margin-bottom: auto;
`
const ButtonContainer = styled.div`
  width: 100%;
  > * {
    width: 100%;
  }
`
const VideoArrow = styled.img`
  position: absolute;
  width: 15%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default ({
  onClick,
  image,
  title,
  roi,
  videoArrow,
  cta,
  link,
  paragraph,
  as,
}) => (
  <Container
    as={as}
    href={link}
    onClick={onClick}
    rel="noreferrer"
    target="_blank"
  >
    <ImageContainer>
      <Image style={{ backgroundImage: `url(${image})` }} />
      {videoArrow && (
        <VideoArrow
          src={require('../../assets/img/arrow-media-right-white.svg')}
        />
      )}
    </ImageContainer>
    <Content>
      <CopyContainer>
        <Title>{title}</Title>
        {paragraph && <Paragraph>{paragraph}</Paragraph>}
      </CopyContainer>
      <ButtonContainer>
        {roi && (
          <RoiContainer>
            <Arrow src={require('../../assets/img/roi-arrow.png')} />
            {/* <Roi>{roi}% ROI</Roi> */}
            <Roi>{roi}</Roi>
          </RoiContainer>
        )}
        <BaseButton text={cta} theme="black" />
      </ButtonContainer>
    </Content>
  </Container>
)
