import React from 'react'
import styled from 'styled-components'
import { only, down, up } from 'styled-breakpoints'
import BaseCard from '../../components/BaseCard/BaseCard'
import BaseButton3 from '../../components/BaseButton3/BaseButton3'
import vars from '../../assets/css/vars/vars'
import BaseSwiper5 from '../../components/BaseSwiper5/BaseSwiper5'
import ScrollAnimation from 'react-animate-on-scroll'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  font-size: 6rem;
  font-weight: ${vars.FONT_WEIGHT_MEDIUM};
  margin-bottom: 1rem;
  line-height: 1.15;
  ${down('md')} {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  ${only('sm')} {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`
const Paragraph = styled.div`
  font-family: ${vars.FONT_FAMILY_2};
  font-weight: ${vars.FONT_WEIGHT_LIGHT};
  margin-bottom: 6rem;
  text-align: center;
  line-height: 1.5;
  max-width: 48rem;
`
const Cards = styled.div`
  display: flex;
  width: 95%;
  overflow: auto;
  position: relative;
  > *:not(:last-child) {
    margin-right: 6.5rem;
    ${only('md')} {
      margin-right: 2rem;
    }
  }
  ${only('sm')} {
    width: calc(100% + 4rem * 2);
  }
`
const CardContainer = styled.div`
  width: 100%;
  display: flex;
  ${up('md')} {
    flex: 1;
  }
  ${only('sm')} {
    padding: 0 2rem;
    flex-shrink: 0;
    width: calc(100% - 2rem);
    display: none;
    &:not(:last-child) {
      margin-right: -2rem;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin: 0 0 18rem;
  > *:not(:last-child) {
    margin-right: 2rem;
  }
  ${only('sm')} {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 9rem;
    > *:not(:last-child) {
      margin-right: 0;
    }
    > * {
      margin: 0 0 2rem 0;
    }
  }
`

const SwiperContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  ${only('sm')} {
    display: block;
  }
`
const Slide = styled.img`
  width: 100%;
  height: auto;
  pointer-events: all;
  user-select: all;
`

export default () => (
  <ScrollAnimation
    animateIn="fade-in-up"
    animateOnce={true}
    style={{ willChange: 'transform, opacity' }}
  >
    <Container>
      <Title>Thought leadership</Title>
      <Paragraph>
        Our insights on how data can transform your business
      </Paragraph>
      <Cards>
        <CardContainer>
          <BaseCard
            image={require('../../assets/img/article-1.jpg')}
            title="Pandemic ups the ante for empathic banking"
            paragraph="How banks must use data to deliver tailored empathic action which genuinely supports their customers during uncertain times."
            cta="Read article"
            link="https://hubs.ly/H0tPy7y0"
          />
        </CardContainer>
        <CardContainer>
          <BaseCard
            image={require('../../assets/img/article-2.jpg')}
            title="How lenders are leveraging transaction data to mitigate material issues in credit models post-COVID"
            paragraph="Banks can provide better customer and business outcomes in a post- pandemic world where they appreciate issues with existing risk models and utilise transaction data as a remedy."
            cta="Read article"
            link="https://hubs.ly/H0tPy7S0"
          />
        </CardContainer>
        <CardContainer>
          <BaseCard
            image={require('../../assets/img/article-3.jpg')}
            title="Lost in translation"
            paragraph="Understand the differences between personalisation in banking and retail."
            cta="Read article"
            link="https://hubs.ly/H0tPy6C0"
          />
        </CardContainer>
      </Cards>

      <SwiperContainer>
        <BaseSwiper5
          slides={[
            <a href="https://hubs.ly/H0tPy7y0" rel="noreferrer" target="_blank">
              <Slide src={require('./assets/article-slide-1.jpg')} />
            </a>,
            <a href="https://hubs.ly/H0tPy7S0" rel="noreferrer" target="_blank">
              <Slide src={require('./assets/article-slide-2.jpg')} />
            </a>,
            <a href="https://hubs.ly/H0tPy6C0" rel="noreferrer" target="_blank">
              <Slide src={require('./assets/article-slide-3.jpg')} />
            </a>,
          ]}
        />
      </SwiperContainer>

      <ButtonContainer>
        <BaseButton3
          url="https://hubs.ly/H0tPxpZ0"
          text="More articles"
          theme="white"
        />
        <BaseButton3
          url="https://hubs.ly/H0tPygx0"
          text="Banking blog"
          theme="white"
        />
      </ButtonContainer>
    </Container>
  </ScrollAnimation>
)
