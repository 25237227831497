import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import { only } from 'styled-breakpoints'
import { FormMode } from '../../config/form-mode'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${vars.COLOR_WHITE_1};
`
const Rainbow = styled.div`
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(
    to left,
    #ebcc79 0%,
    #f09c46 11%,
    #df5d47 22%,
    #bb5f6f 34%,
    #8e74bf 44%,
    #4069ad 56%,
    #4aadb6 68%,
    #46d5a3 78%,
    #82c074 89%,
    #ebcc79 100%
  );
`
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Content = styled.div`
  width: 100%;
  max-width: ${vars.CONTENT_WIDTH};
  position: relative;
  display: flex;
`
const Button = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 2rem 4rem;
  height: 100%;
  cursor: pointer;
  &:not(:last-child) {
    border-right: 1px solid #000;
  }
  ${only('sm')} {
    font-size: 1.4rem;
    padding: 1.5rem;
  }
`

export default ({ updateFormState }) => (
  <Container>
    <Rainbow />
    <ContentContainer>
      <Content>
        <Button
          onClick={() =>
            updateFormState({ formIsOpen: true, formMode: FormMode.BROCHURE })
          }
        >
          Download brochure
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1363761&amp;conversionId=3136281&amp;fmt=gif"
          />
        </Button>
        <Button
          onClick={() =>
            updateFormState({ formIsOpen: true, formMode: FormMode.DEMO })
          }
        >
          Request a demo
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src="https://px.ads.linkedin.com/collect/?pid=1363761&amp;conversionId=3136289&amp;fmt=gif"
          />
        </Button>
      </Content>
    </ContentContainer>
  </Container>
)
