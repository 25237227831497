import React from 'react'
import styled from 'styled-components'
import BaseSection6 from '../../components/BaseSection6/BaseSection6'
import BaseSwiper3 from '../../components/BaseSwiper3/BaseSwiper3'

const IllustrationMd = styled.img`
  width: 70%;
`
const IllustrationLg = styled.img`
  width: 100%;
`
const Slide = styled.img`
  width: auto;
  // height: 350px;
  height: 300px;
`
const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
`
const Span = styled.span``

export default () => (
  <BaseSection6
    title={
      <Span>
        Transaction data is the key to delighting your customers while unlocking
        a range of high value benefits across your business
      </Span>
    }
    buttonText2="Case studies"
    buttonText1="Why Q.Refinery"
    videoUrl="5032n_bT_KA"
    paragraph={
      <Span>
        The customer insights in your transaction data is a source of
        competitive advantage, unlocking opportunities across your bank’s entire
        value chain – now and into the future.
      </Span>
    }
    quote="With hindsight, it would have taken us years and significant resources to possibly achieve the same levels of coverage and quality as Q.Refinery. This would have been time within which we wouldn't have had our team focusing on driving value from the data."
    quoteIcon={require('./assets/quote-icon.png')}
    quoteIcon2={require('./assets/quote-icon.png')}
    illustrationSm={
      <SwiperContainer>
        <BaseSwiper3
          slides={[
            <Slide src={require('./assets/slide-1.png')} />,
            <Slide src={require('./assets/slide-2.png')} />,
            <Slide src={require('./assets/slide-3.png')} />,
            <Slide src={require('./assets/slide-4.png')} />,
          ]}
        />
      </SwiperContainer>
    }
    illustrationMd={
      <IllustrationMd src={require('./assets/illustration-lg.png')} />
    }
    illustrationLg={
      <IllustrationLg src={require('./assets/illustration-lg.png')} />
    }
  />
)
