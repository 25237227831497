export const FormMode = {
  BROCHURE: 'BROCHURE',
  DEMO: 'DEMO',
  CASE1: 'CASE1',
  CASE2: 'CASE2',
  CASE3: 'CASE3',
  CASE4: 'CASE4',
  CASE5: 'CASE5',
  CASE6: 'CASE6',
  TALKTOUS: 'TALKTOUS',
}
