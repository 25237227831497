import React from 'react'
import BaseLayout from '../components/BaseLayout/BaseLayout'
import BaseLayout2 from '../components/BaseLayout2/BaseLayout2'
import BaseLayout3 from '../components/BaseLayout3/BaseLayout3'
import Header from '../partials/Header/Header'
import Gradient from '../partials/Gradient/Gradient'
import Section1 from '../partials/Section1/Section1'
import Section2 from '../partials/Section2/Section2'
import Section3 from '../partials/Section3/Section3'
import Section4 from '../partials/Section4/Section4'
// import VideoArticles from '../partials/VideoArticles/VideoArticles'
import Articles from '../partials/Articles/Articles'
import Action from '../partials/Action/Action'
import CaseStudies from '../partials/CaseStudies/CaseStudies'
import BaseFooter2 from '../components/BaseFooter2/BaseFooter2'
import BaseForm from '../components/BaseForm/BaseForm'
// import BaseFormMobile from '../components/BaseFormMobile/BaseFormMobile'
import BaseStickyAction from '../components/BaseStickyAction/BaseStickyAction'
import BaseStickyActionMobile from '../components/BaseStickyActionMobile/BaseStickyActionMobile'
import BaseNavigationMobile from '../components/BaseNavigationMobile/BaseNavigationMobile'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../assets/css/theme/theme'
import vars from '../assets/css/vars/vars'
import Helmet from 'react-helmet'
import BaseLineAnimation from '../components/BaseLineAnimation/BaseLineAnimation'
import anime from 'animejs'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { DefaultStyles } from '../assets/css/defaults/defaults'
import { up, down } from 'styled-breakpoints'
import PageTransition from 'gatsby-v2-plugin-page-transitions'

const Page = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
`
const Sections = styled.div`
  position: relative;
`
const StickyActionContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform ${vars.TRANSITION_SETTINGS};
  z-index: 2;
  &.visible {
    transform: translateY(0%);
  }
  &.invisible {
    // display: none;
    transform: translateY(100%);
  }
  ${down('md')} {
    display: none;
  }
`
const StickyActionMobileContainer = styled.div`
  ${up('md')} {
    display: none;
  }
  position: sticky;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform ${vars.TRANSITION_SETTINGS};
  // z-index: 2;
  z-index: 999999999;
  &.visible {
    transform: translateY(0%);
  }
  &.invisible {
    display: none;
    transform: translateY(100%);
  }
`
const NavigationMobileContainer = styled.div`
  ${up('md')} {
    display: none;
  }
  position: sticky;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  // z-index: 2;
  z-index: 999999999;
  transform: translateY(100%);
  transition: transform ${vars.TRANSITION_SETTINGS};
  &.visible {
    transform: translateY(0%);
  }
  &.invisible {
    display: none;
    transform: translateY(100%);
  }
`
// const FormMobileContainer = styled.div`
//   display: none;
//   width: 100%;
//   height: auto;
//   ${down('md')} {
//     display: block;
//   }
// `
const LineAnimationContainer = styled.div`
  position: absolute;
  transform: translateX(-50%);
  height: 75%;
  left: 50%;
  top: 1.7%;
  z-index: 1;
  width: 100%;
  max-width: 1784.86px;
  @media (max-width: 1130px) {
  }
  @media (max-width: 1030px) {
    top: 1%;
    height: 75.7%;
  }
  @media (max-width: 995px) {
    height: 75.7%;
  }
  @media (max-width: 930px) {
  }
  @media (max-width: 805px) {
  }
  @media (max-width: 767px) {
    height: 78%;
  }
  @media (max-width: 426px) {
  }
  @media (max-width: 376px) {
  }
  @media (max-width: 373px) {
  }
`

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.isAnimating = false
    this.formContainerRef = React.createRef()
    this.state = {
      formMode: null,
      formIsOpen: false,
      displayStickyAction: false,
      displayNavigationMobile: false,
    }
  }
  setStateAsync(state) {
    return new Promise(resolve => this.setState(state, resolve))
  }
  async updateFormState({ formIsOpen, formMode }) {
    if (this.isAnimating) return
    this.isAnimating = true
    if (formIsOpen) {
      await this.setStateAsync({ formIsOpen, formMode })
      disableBodyScroll(this.formContainerRef.current)
      await this.animateForm('in')
      this.isAnimating = false
    } else {
      await this.animateForm('out')
      await this.setStateAsync({ formIsOpen, formMode })
      clearAllBodyScrollLocks()
      this.isAnimating = false
    }
  }
  animateForm(direction) {
    const tl = anime.timeline({ targets: this.formContainerRef.current })
    tl.add(
      {
        translateY: direction === 'in' ? [100, 0] : [0, 0],
        easing: 'easeOutQuint',
        duration: 200,
      },
      0
    )
    tl.add(
      {
        opacity: direction === 'in' ? [0, 1] : [1, 0],
        easing: 'easeInOutQuad',
        duration: 200,
      },
      0
    )
    return tl.finished
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <DefaultStyles />
        <Helmet>
          <title>Q.Refinery : Quantium</title>
          <meta
            name="description"
            content="When expertly harnessed and strategically applied, data can be transformational. Quantium offers a 16 year track record of innovation in data science. We combine the best of human and artificial intelligence to power possibilities for individuals, organisations and society."
          />
          <link
            rel="shortcut icon"
            type="image/png"
            href={require('../assets/img/favicon.ico')}
          />
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/2924288.js"
          ></script>
        </Helmet>
        <PageTransition>
          <Page>
            <BaseLayout
              slot={
                <Header
                  anchor1="#section1"
                  anchor2="#section2"
                  anchor3="#section4"
                  anchor4="#section-case-studies"
                  anchor5="#section3"
                  updateFormState={this.updateFormState.bind(this)}
                  onIntersectionUpdate={isIntersecting =>
                    this.setState({
                      displayStickyAction: !isIntersecting,
                      displayNavigationMobile: isIntersecting,
                    })
                  }
                />
              }
              padding="6rem 4rem"
            />
            <Sections>
              <LineAnimationContainer>
                <BaseLineAnimation />
              </LineAnimationContainer>
              <BaseLayout
                anchorRef="section1"
                slot={<Section1 />}
                backgroundColor={vars.COLOR_BLACK_1}
              />
              <BaseLayout
                anchorRef="section2"
                slot={<Section2 />}
                backgroundColor={vars.COLOR_BLACK_1}
              />
              <BaseLayout
                anchorRef="section3"
                slot={<Section3 />}
                backgroundColor={vars.COLOR_BLACK_1}
              />
              <BaseLayout
                anchorRef="section4"
                slot={<Section4 />}
                backgroundColor={vars.COLOR_BLACK_1}
              />
            </Sections>
            <Gradient />
            {/* <BaseLayout2
              slot={<VideoArticles />}
              backgroundColor={vars.COLOR_WHITE_1}
            /> */}
            <BaseLayout2
              anchorRef="section-case-studies"
              slot={
                <CaseStudies
                  updateFormState={this.updateFormState.bind(this)}
                />
              }
              backgroundColor={vars.COLOR_WHITE_1}
            />
            <BaseLayout2
              slot={<Articles />}
              backgroundColor={vars.COLOR_WHITE_1}
            />
            <BaseLayout
              slot={
                <Action
                  updateFormState={this.updateFormState.bind(this)}
                  onIntersectionUpdate={isIntersecting =>
                    this.setState({
                      displayStickyAction: !isIntersecting,
                      displayNavigationMobile: isIntersecting,
                    })
                  }
                />
              }
              backgroundColor={vars.COLOR_GRAY_1}
            />
            {/* <FormMobileContainer>
              {!this.state.formIsOpen && (
                  <BaseLayout3
                    slot={<BaseFormMobile formMode={this.state.formMode} />}
                    backgroundColor={vars.COLOR_GRAY_1}
                  />
                )
              }
            </FormMobileContainer> */}
            <BaseLayout3
              slot={<BaseFooter2 />}
              backgroundColor={vars.COLOR_BLACK_1}
            />
            {this.state.formIsOpen && (
              <BaseLayout
                padding="0rem 2rem"
                alignItems="flex-start"
                ref={this.formContainerRef}
                fixed={true}
                slot={
                  <BaseForm
                    formMode={this.state.formMode}
                    close={() =>
                      this.updateFormState({
                        formIsOpen: false,
                        formMode: null,
                      })
                    }
                  />
                }
                backgroundColor={vars.COLOR_GRAY_1}
              />
            )}
          </Page>
        </PageTransition>
        <NavigationMobileContainer
          className={
            this.state.displayNavigationMobile
              ? this.state.displayNavigationMobile && 'visible'
              : !this.state.displayNavigationMobile && 'invisible'
          }
        >
          <BaseNavigationMobile
            anchor1="#section1"
            anchor2="#section2"
            anchor3="#section3"
            anchor4="#section-case-studies"
            anchor5="#section4"
          />
        </NavigationMobileContainer>
        <StickyActionContainer
          className={
            this.state.displayStickyAction
              ? this.state.displayStickyAction && 'visible'
              : !this.state.displayStickyAction && 'invisible'
          }
        >
          <BaseStickyAction updateFormState={this.updateFormState.bind(this)} />
        </StickyActionContainer>
        <StickyActionMobileContainer
          className={
            this.state.displayStickyAction
              ? this.state.displayStickyAction && 'visible'
              : !this.state.displayStickyAction && 'invisible'
          }
        >
          <BaseStickyActionMobile
            updateFormState={this.updateFormState.bind(this)}
          />
        </StickyActionMobileContainer>
      </ThemeProvider>
    )
  }
}
