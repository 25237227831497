import React from 'react'
import styled from 'styled-components'
import BaseSection7 from '../../components/BaseSection7/BaseSection7'

const IllustrationSm = styled.img`
  width: 100%;
  // max-width: 30rem;
`
const IllustrationMd = styled.img`
  width: 70%;
`
const IllustrationLg = styled.img`
  width: 85%;
`
const Span = styled.span``

export default () => (
  <BaseSection7
    title={
      <Span>
        Q.Refinery delivers transformative banking experiences to major
        financial institutions globally
      </Span>
    }
    videoAnchor="videoAnchor4"
    buttonText1="Why Q. Refinery"
    buttonText2="Our coverage"
    paragraph={
      <Span>
        Let's talk about how within weeks we can industrialise your transaction
        data to power enhanced personalisation and super-charged predictive
        modelling.
      </Span>
    }
    illustrationSm={
      <IllustrationSm src={require('./assets/illustration-sm.png')} />
    }
    illustrationMd={
      <IllustrationMd src={require('./assets/illustration-lg.png')} />
    }
    illustrationLg={
      <IllustrationLg src={require('./assets/illustration-lg.png')} />
    }
  />
)
