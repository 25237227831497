import React from 'react'
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'
// import { only } from 'styled-breakpoints'
import { gsap, Power2 } from 'gsap'

const Container = styled.div`
  position: relative;
  font-size: 1.45rem;
  font-weight: 500;
  background: #000;
  width: 100%;
`
const Rainbow = styled.div`
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(
    to left,
    #ebcc79 0%,
    #f09c46 11%,
    #df5d47 22%,
    #bb5f6f 34%,
    #8e74bf 44%,
    #4069ad 56%,
    #4aadb6 68%,
    #46d5a3 78%,
    #82c074 89%,
    #ebcc79 100%
  );
`
const QuickLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #fff;
`
const SubOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  color: #fff;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #3d3d3d;
  }
`
const ArrowContainer = styled.div`
  position: relative;
  background: red;
  margin-top: -0.9rem;
  margin-left: 1rem;
`
const ArrowUp = styled.img`
  position: absolute;
  width: 0.8rem;
`
const ArrowDown = styled.img`
  position: absolute;
  width: 0.8rem;
`

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIsOpen: false,
    }
  }
  componentDidMount() {
    this.toggleMobileNavigation()
  }
  toggleMobileNavigation = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen })
    const tl = new gsap.timeline()
    this.state.menuIsOpen
      ? tl.to('.container', { duration: 0.5, y: 0, ease: Power2.easeOut })
      : tl.to('.container', { duration: 0.5, y: 250, ease: Power2.easeOut })
  }
  render() {
    return (
      <Container className="container">
        <div>
          <QuickLink onClick={this.toggleMobileNavigation}>
            <div>Quick links</div>
            <ArrowContainer>
              {this.state.menuIsOpen && (
                <ArrowUp src={require('../../assets/img/arrow-up-white.svg')} />
              )}
              {!this.state.menuIsOpen && (
                <ArrowDown
                  src={require('../../assets/img/arrow-down-white.svg')}
                />
              )}
            </ArrowContainer>
          </QuickLink>
          <Rainbow />
        </div>
        <div>
          <SubOption
            onClick={() => {
              this.toggleMobileNavigation()
              scrollTo(`${this.props.anchor1}`)
            }}
          >
            Why Q.Refinery
          </SubOption>
          <SubOption
            onClick={() => {
              this.toggleMobileNavigation()
              scrollTo(`${this.props.anchor2}`)
            }}
          >
            Results
          </SubOption>
          <SubOption
            onClick={() => {
              this.toggleMobileNavigation()
              scrollTo(`${this.props.anchor3}`)
            }}
          >
            Our global footprint
          </SubOption>
          <SubOption
            onClick={() => {
              this.toggleMobileNavigation()
              scrollTo(`${this.props.anchor5}`)
            }}
          >
            How it works
          </SubOption>
          <SubOption
            onClick={() => {
              this.toggleMobileNavigation()
              scrollTo(`${this.props.anchor4}`)
            }}
          >
            Case studies
          </SubOption>
        </div>
      </Container>
    )
  }
}
