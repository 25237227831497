import React from 'react'
import { only, down } from 'styled-breakpoints'
import styled from 'styled-components'
import scrollTo from 'gatsby-plugin-smoothscroll'

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6rem;
`
const SubNavigation = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.5rem;
  cursor: pointer;
  ${down('md')} {
    display: none;
  }
`
const ArrowDown = styled.img`
  width: 0.8rem;
  margin: -0.2rem 1rem 0;
`
const ArrowRight = styled.img`
  width: 0.4rem;
  margin-left: 0.8rem;
`
const Return = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: right;
  ${down('md')} {
    font-size: 1.1rem;
  }
`
const Logo = styled.img`
  width: 21.5rem;
  ${only('sm')} {
    // width: 15.5rem;
    width: 14rem;
  }
`
const Anchor = styled.div`
  display: inline-block;
`

export default class extends React.Component {
  render() {
    return (
      <Navigation>
        <Anchor>
          <Logo src={require('../../assets/img/logo-refinery.png')} />
        </Anchor>

        <SubNavigation onClick={() => scrollTo(`${this.props.anchor1}`)}>
          Why Q.Refinery
          <ArrowDown src={require('../../assets/img/arrow-down.svg')} />
        </SubNavigation>

        <SubNavigation onClick={() => scrollTo(`${this.props.anchor2}`)}>
          Results
          <ArrowDown src={require('../../assets/img/arrow-down.svg')} />
        </SubNavigation>

        <SubNavigation onClick={() => scrollTo(`${this.props.anchor5}`)}>
          Our global footprint
          <ArrowDown src={require('../../assets/img/arrow-down.svg')} />
        </SubNavigation>

        <SubNavigation onClick={() => scrollTo(`${this.props.anchor3}`)}>
          How it works
          <ArrowDown src={require('../../assets/img/arrow-down.svg')} />
        </SubNavigation>

        <SubNavigation onClick={() => scrollTo(`${this.props.anchor4}`)}>
          Case studies
          <ArrowDown src={require('../../assets/img/arrow-down.svg')} />
        </SubNavigation>

        <a href="https://quantium.com/" rel="noreferrer" target="_blank">
          <Return>
            Return to main
            <br />
            Quantium website
            <ArrowRight
              src={require('../../assets/img/arrow-right-black.svg')}
            />
          </Return>
        </a>
      </Navigation>
    )
  }
}
