import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'

const theme = {
  white: {
    backgroundColor: vars.COLOR_WHITE_1,
    color: vars.COLOR_BLACK_1,
    arrow: require('../../assets/img/arrow-right-black.svg'),
  },
  black: {
    backgroundColor: vars.COLOR_BLACK_1,
    color: vars.COLOR_WHITE_1,
    arrow: require('../../assets/img/arrow-right-white.svg'),
  },
}

const Button = styled.button`
  position: relative;
  padding: ${props => props.padding || '1.5rem 3rem'};
  font-family: ${vars.FONT_FAMILY_2};
  font-size: 1.8rem;
  color: ${props => theme[props.theme].color};
  background-color: ${props => theme[props.theme].backgroundColor};
  white-space: nowrap;
  transition: opacity ${vars.TRANSITION_SETTINGS};
  text-align: ${props => props.textAlign};
  pointer-events: ${props => props.pointerEvents};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
const Arrow = styled.img`
  position: absolute;
  transform: translateY(-50%);
  height: 1.2rem;
  top: 50%;
  right: 3rem;
  pointer-events: none;
`
const Spinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  &::after {
    content: '';
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    border-color: rgb(76, 76, 76) rgb(76, 76, 76) rgb(211, 211, 211)
      rgb(211, 211, 211);
    animation: spin 0.8s linear 0s infinite normal none running;
    transform: translate(-50%, -50%);
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const Content = styled.div`
  visibility: ${props => props.visibility};
`

export default class extends React.Component {
  render() {
    return (
      <Button
        theme={this.props.theme}
        onClick={this.props.onClick && this.props.onClick}
        textAlign={this.props.textAlign}
        padding={this.props.padding}
        pointerEvents={this.props.loading ? 'none' : 'all'}
      >
        {this.props.loading && <Spinner />}
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt=""
          src={this.props.pixel}
        />
        <Content visibility={this.props.loading ? 'hidden' : 'visible'}>
          {this.props.text}
          {this.props.displayArrow && (
            <Arrow src={require('../../assets/img/arrow-right-white.svg')} />
          )}
        </Content>
      </Button>
    )
  }
}
