import React from 'react'
import styled from 'styled-components'
import vars from '../../assets/css/vars/vars'
import BaseButtonPixel from '../BaseButtonPixel/BaseButtonPixel'
import { FormMode } from '../../config/form-mode'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${vars.COLOR_WHITE_1};
`
const Rainbow = styled.div`
  width: 100%;
  height: 0.5rem;
  background: linear-gradient(
    to left,
    #ebcc79 0%,
    #f09c46 11%,
    #df5d47 22%,
    #bb5f6f 34%,
    #8e74bf 44%,
    #4069ad 56%,
    #4aadb6 68%,
    #46d5a3 78%,
    #82c074 89%,
    #ebcc79 100%
  );
`
const ContentContainer = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  display: flex;
  justify-content: center;
`
const Content = styled.div`
  width: 100%;
  max-width: ${vars.CONTENT_WIDTH};
  position: relative;
  display: flex;
  justify-content: space-between;
`
const Logo = styled.img`
  max-height: 5rem;
  height: 100%;
`
const ButtonsContainer = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 3rem;
  }
`
const Anchor = styled.div``

export default ({ updateFormState }) => (
  <Container>
    <Rainbow />
    <ContentContainer>
      <Content>
        <Anchor>
          <Logo src={require('../../assets/img/logo-refinery.png')} />
        </Anchor>
        <ButtonsContainer>
          <BaseButtonPixel
            padding="1.5rem 6rem"
            text="Download brochure"
            theme="black"
            pixel="https://px.ads.linkedin.com/collect/?pid=1363761&amp;conversionId=3136281&amp;fmt=gif"
            onClick={() =>
              updateFormState({ formIsOpen: true, formMode: FormMode.BROCHURE })
            }
          />
          <BaseButtonPixel
            padding="1.5rem 6rem"
            text="Request demo"
            theme="black"
            pixel="https://px.ads.linkedin.com/collect/?pid=1363761&amp;conversionId=3136289&amp;fmt=gif"
            onClick={() =>
              updateFormState({ formIsOpen: true, formMode: FormMode.DEMO })
            }
          />
        </ButtonsContainer>
      </Content>
    </ContentContainer>
  </Container>
)
